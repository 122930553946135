var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["drivers-main-container", { android: _vm.isAndroid }] },
    [_c("header-mobile"), _c("footer-mobile"), _c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }