<template>
  <div :class="['drivers-main-container', { android: isAndroid }]">
    <header-mobile />
    <footer-mobile />
    <router-view />
  </div>
</template>

<script>
import {
  DR_FETCH_TIMESLOT_LIST,
  DR_GET_CURRENT_TIME,
  DR_GET_RUN_TIMER,
  DR_GET_SETUP_TIME,
  DR_GET_SLOAT_PRICE_DATA,
  DR_UPDATE_CURRENT_TIME,
  DR_UPDATE_RUN_TIMER,
  DR_UPDATE_SLOAT_PRICE_DATA,
} from '@/store/actions'
import { getIsAndroid } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import FooterMobile from '@/components/layouts/drivers/footer/index.vue'
import HeaderMobile from '@/components/layouts/drivers/header/index.vue'

export default {
  name: 'Drivers',
  components: {
    HeaderMobile,
    FooterMobile,
  },
  data() {
    return {
      timer: null,
    }
  },

  computed: {
    ...mapGetters({
      currentTimeState: DR_GET_CURRENT_TIME,
      runTimer: DR_GET_RUN_TIMER,
      getSlotPriceData: DR_GET_SLOAT_PRICE_DATA,
      endTime: DR_GET_SETUP_TIME,
    }),
    isAndroid() {
      return getIsAndroid()
    },
  },
  watch: {
    getSlotPriceData: {
      immediate: true,
      handler(value) {
        if (!value) {
          clearTimeout(this.timer)
          this.updateRunTimer(false)
        }
      },
    },
    runTimer: {
      immediate: true,
      handler(value) {
        if (value === false) {
          localStorage.removeItem('endTime')
          clearTimeout(this.timer)
          this.updateSloatPriceData()
        }

        if (value === true && !localStorage.getItem('endTime')) {
          localStorage.setItem(
            'endTime',
            new Date().getTime() + this.currentTimeState * 1000,
          )
          this.startTimer()
        } else if (value === true && this.currentTimeState > 0) {
          this.startTimer()
        }
      },
    },
    currentTimeState: {
      immediate: true,
      handler(value) {
        if (value <= 1) {
          clearTimeout(this.timer)
          this.updateRunTimer(false)
        }
      },
    },
  },
  mounted() {
    this.fetchTimeSlotsList()
  },

  methods: {
    ...mapActions({
      fetchTimeSlotsList: DR_FETCH_TIMESLOT_LIST,
      updateTime: DR_UPDATE_CURRENT_TIME,
      updateRunTimer: DR_UPDATE_RUN_TIMER,
      updateSloatPriceData: DR_UPDATE_SLOAT_PRICE_DATA,
    }),

    startTimer() {
      this.timer = setInterval(() => {
        if (
          new Date().getTime() >= localStorage.getItem('endTime') ||
          this.currentTimeState < 0
        ) {
          localStorage.removeItem('endTime')
          clearTimeout(this.timer)
          this.updateSloatPriceData()
        } else {
          this.updateTime(
            Math.ceil(
              (localStorage.getItem('endTime') - new Date().getTime()) / 1000,
            ),
          )
        }
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  },
}
</script>

<style lang="sass">
#app
  background-color: $color-white!important

body
  background-color: $color-white!important

  .main-div
    background-color: $color-white
.drivers-main-container
  background: white
  padding: 15px
  max-height: 80vh
  overflow: scroll
  min-width: 320px
  max-width: 1024px
  margin: 40px auto 0
  &.android
    margin-top: 0
    padding: 40px 15px 80px
    max-height: unset
  .el-form-item__label
    margin-bottom: 6px
    width: 100% !important
    &:before
      font-size: 14px
  i
    @media only screen and (max-width: 550px)
      &:before
        font-size: 14px!important
</style>
